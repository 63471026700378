const showForFields = [...document.querySelectorAll('[data-sync]')];

showForFields.forEach(field => {
    const fieldName = field.dataset.sync;
    const syncFromField = document.querySelector(
        `[name="${fieldName}"]:not([type="hidden"])`
    );

    syncFromField.addEventListener('change', event => {
        // We want the text if it's a select
        if (event.target.tagName === 'SELECT') {
            field.value = event.target.options[event.target.selectedIndex].textContent;
        } else {
            field.value = event.target.value;
        }

        field.disabled = false;
    });

    syncFromField.dispatchEvent('change');
});
