const printTriggers = [...document.querySelectorAll('.js-printCart')];

if (printTriggers.length) {
    printTriggers.forEach(printTrigger => {
        printTrigger.addEventListener('click', e => {
            e.preventDefault();
            printTrigger.classList.add('-processing');

            const societyLogo     = document.querySelector('.brand');
            const printContainers = document.querySelectorAll(`.print-cart`);
            const removeLinks     = document.querySelectorAll('.item .link');
            const cartTotal       = document.querySelector(`[data-cart-total]`);

            removeLinks.forEach(removeLink => {
                    removeLink.style.display = 'none'
                }
            )

            const WinPrint = window.open('', 'PRINT', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');
            const cssLinkTag = document.querySelector('link[rel="stylesheet"][href="/css/main.css"]').cloneNode(true);
            console.log(cssLinkTag)

            WinPrint.document.write(societyLogo.innerHTML);

            printContainers.forEach(printContainer => {
                WinPrint.document.write(printContainer.innerHTML);
            });

            WinPrint.document.write(cartTotal.innerHTML);
            WinPrint.document.head.append(cssLinkTag);
            WinPrint.document.close();
            WinPrint.document.body.style.backgroundColor = '#fff';
            WinPrint.document.body.style.overflowY = 'visible';
            WinPrint.document.body.style.height = 'auto';


            WinPrint.setTimeout(() => {
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
                printTrigger.classList.remove('-processing');
                    removeLinks.forEach(removeLink => {
                        removeLink.style.display = 'initial'
                    }
                )
            }, 1000)
        });
    })
}
