/**
 * Logic for Tracker Component
 * Author: Imarc 2019
 */

const trackers = [...document.querySelectorAll('.js-tracker')];

if (trackers.length) {
    trackers.forEach(function setStrokeDashArray(tracker) {
        const completed = tracker.dataset.completed * 1;
        const required = tracker.dataset.required * 1;
        const radius = tracker.getAttribute('r') * 1;
        const circumference = Math.round(2 * Math.PI * radius);
        const percent = completed / required;
        const circumferenceFraction = percent * circumference;
        const finalDonutStyle = `${circumferenceFraction} ${circumference}`;

        // eslint-disable-next-line no-param-reassign
        tracker.style.strokeDasharray = finalDonutStyle;
    });
}
