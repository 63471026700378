import { renderData } from 'peninsula-editor/src/modules/renderData';

const dynamicModules = [...document.querySelectorAll('[data-endpoint]')];
const contentEvent = new Event('content-populated');

if (dynamicModules.length) {
    const promises = dynamicModules.map(module => {
        return renderData(module);
    });

    Promise.all(promises).then(() => {
        window.dispatchEvent(contentEvent);
    });
} else {
    window.dispatchEvent(contentEvent);
}
