import tippy from 'tippy.js';

const tooltips = [...document.querySelectorAll('[data-tooltip]')];
const htmlTooltips = [...document.querySelectorAll('[data-tooltip-html]')];

if (tooltips.length > 0) {
    tooltips.forEach(tooltip => {
        const config = {};
        const content = tooltip.dataset.tooltip;
        config.content = content;

        if (tooltip.dataset.tooltipOffset) {
            const offsetString = tooltip.dataset.tooltipOffset;
            const offsetCoordinates = JSON.parse(offsetString);
            config.offset = offsetCoordinates;
        }

        if (tooltip.dataset.tooltipAlign) {
            config.placement = tooltip.dataset.tooltipAlign;
        }

        tippy(tooltip, config);
    });
}

if (htmlTooltips.length > 0) {
    htmlTooltips.forEach(tooltip => {
        const config = {
            allowHTML: true,
        };

        const id = tooltip.dataset.tooltipHtml;

        const tooltipContentElement = document.querySelector(id);

        if (tooltipContentElement) {
            const content = tooltipContentElement.innerHTML;
            config.content = content;

            if (tooltip.dataset.tooltipOffset) {
                const offsetString = tooltip.dataset.tooltipOffset;
                const offsetCoordinates = JSON.parse(offsetString);
                config.offset = offsetCoordinates;
            }

            if (tooltip.dataset.tooltipAlign) {
                config.placement = tooltip.dataset.tooltipAlign;
            }

            tippy(tooltip, config);
        }
    });
}
