/**
 * Logic for Mobile Menu
 * Author: Imarc 2019
 */

// DOM Elements
const mobileMenuButton = document.querySelector('.js-mobileMenuButton');
const submenuBackLinks = [...document.querySelectorAll('.js-submenuBack')];
const mobileMenus = [...document.querySelectorAll('.js-mobileMenu')];
const submenuLinks = [...document.querySelectorAll('.js-submenuLink')];
const submenus = [...document.querySelectorAll('.js-submenu')];
const html = document.querySelector('html');
const body = document.querySelector('body');

// Global Values
const activeClass = '-opened';

// Functions
const removeActiveClass = element => {
    element.classList.remove(activeClass);
};

const toggleActiveClass = element => {
    element.classList.toggle(activeClass);
};

/**
 * Open Mobile Menu
 */
mobileMenuButton.addEventListener('click', () => {
    mobileMenuButton.classList.toggle(activeClass);
    html.classList.toggle('scroll-lock');
    body.classList.toggle('-overlay');

    submenus.forEach(removeActiveClass);
    mobileMenus.forEach(toggleActiveClass);
});

/**
 * Open Submenu
 */
submenuLinks.forEach(submenuLink => {
    submenuLink.addEventListener('click', () => {
        const submenuLinkParent = submenuLink.parentNode;
        const submenu = submenuLinkParent.querySelector('.js-submenu');

        // submenus.forEach(removeActiveClass);
        submenu.classList.toggle(activeClass);
    });
});

/**
 * Close Submenu
 */
submenuBackLinks.forEach(submenuBack => {
    submenuBack.addEventListener('click', () => {
        submenus.forEach(removeActiveClass);
    });
});


// Check if the Window Inner Width is equal to 
// or greater than LG variable in variables.scss on window resize
window.addEventListener('resize', e => {

    if(window.innerWidth >= 1024){
        submenus.forEach(removeActiveClass);
        mobileMenus.forEach(removeActiveClass);
        mobileMenuButton.classList.remove(activeClass);
    }
    
})