const bannerAds = [...document.querySelectorAll('[data-module="banner-ad"')];

function elementInViewport(el) {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
    );
}

function triggerEvent(event, label) {
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push({
            event,
            category: 'banner-ad',
            label,
        });
    }

    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', event, {
            event_category: 'banner-ad',
            event_label: label,
        });
    }

    if (typeof window.ga !== 'undefined') {
        window.ga('send', 'event', 'banner-ad', event, label);
    }
}

if (bannerAds.length) {
    
    bannerAds.forEach(bannerAd => {
        const label = bannerAd.getAttribute('label');
        let elementViewed = false;

        if (!elementViewed && elementInViewport(bannerAd)) {
            triggerEvent('view', label);
            elementViewed = true;
        }

        if (!elementViewed) {
            window.addEventListener('scroll', () => {
                if (elementInViewport(bannerAd) && !elementViewed) {
                    triggerEvent('view', label);
                    elementViewed = true;
                }
            });
        }

        bannerAd.addEventListener('click', () => {
            triggerEvent('click', label);
        });
    });
}
