document.addEventListener('click', function(e) {
    let parents = [];

    for (let node = e.target; node.parentElement; node = node.parentElement) {
        parents.push(node);
    }

    document.querySelectorAll('[data-reveal]').forEach(item => {
        let triggers = document.querySelectorAll(item.dataset.reveal);
        let matches  = parents.filter(element => element.matches(item.dataset.reveal));

        if (matches.length || (item.classList.contains('-revealed') && !parents.includes(item))) {
            item.classList.toggle('-revealed');
            triggers.forEach(trigger => {
                trigger.classList.toggle('-active');
            });
        }
    });
});
