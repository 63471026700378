
// Get All Triggers On The Page
const triggers = document.querySelectorAll('[data-mobile-trigger]');
   
triggers.forEach(trigger => {

    // Grab all targets that match the trigger 
    const targets = document.querySelectorAll(trigger.dataset.mobileTrigger)

    // Toggle -triggered class on every matching target on click
    trigger.addEventListener('click', e => {

        trigger.classList.toggle('mobileActive')
        targets.forEach(target => target.classList.toggle("-triggered"))

    })

});
