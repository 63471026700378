import Macy from 'macy';
import toggleGroup from './toggleGroup';

const masonryGrids = [...document.querySelectorAll('.js-masonry')];

if (masonryGrids.length) {
    masonryGrids.forEach(function initalizeGrid(grid) {
        // eslint-disable-next-line no-new
        const masonry = Macy({
            container: grid,
            columns: 2,
            margin: 27,
            breakAt: {
                1024: {
                    columns: 1,
                },
            },
        });

        toggleGroup({
            triggerClass: '.js-showHidden',
            elementClass: '.js-hidden',
            toggleClass: '-hidden',
            preventDefault: false,
            callback: () => masonry.recalculate(),
        });
    });
}
