/**
 * Logic for toggling a groups classes.
 * Author: Imarc 2020
 *
 *
 * @param Object Configuration
 */

export default function toggleGroup(passedConfig) {
    const config = {
        elementClass: passedConfig.elementClass || '.js-expand',
        triggerClass: passedConfig.triggerClass || '.js-expandTrigger',
        toggleClass: passedConfig.toggleClass || '-hidden',
        swapText: passedConfig.swapText || null,
        preventDefault: !passedConfig.preventDefault ? passedConfig.preventDefault : true,
        callback: passedConfig.callback,
    };

    const triggers = document.querySelectorAll(config.triggerClass);
    const group = [...document.querySelectorAll(config.elementClass)];

    triggers.forEach(function applyClickEvent(trigger) {
        const initialText = trigger.innerHTML;

        trigger.addEventListener('click', e => {
            if (config.preventDefault) {
                // Prevent form submission if applicable
                e.preventDefault();
            }

            group.forEach(groupItem => {
                groupItem.classList.toggle(config.toggleClass);
            });

            if (config.swapText) {
                // eslint-disable-next-line no-param-reassign
                trigger.innerHTML =
                    trigger.innerHTML !== config.swapText ? config.swapText : initialText;
            }

            if (typeof config.callback !== 'undefined') {
                config.callback();
            }
        });
    });
}
