/**
 * Sniffs out 'data-submit' attributes and applys form submittal behavior
 * Author: Imarc 2020
 */

const submitAnchors = [...document.querySelectorAll('[data-submit]')];

if (submitAnchors.length > 0) {
    submitAnchors.forEach(anchor => {
        const submitId = anchor.dataset.submit;
        const associatedForm = document.querySelector(`form#${submitId}`);

        anchor.addEventListener('click', event => {
            event.preventDefault();

            associatedForm.submit();
        });
    });
}
