/**
 * For closing site-wide alert
 */

const siteAlertClose = document.querySelector('.js-siteAlertClose');
const siteAlert = document.querySelector('.js-siteAlert');
const alertClosed = sessionStorage.getItem('alertClosed') * 1;

if (siteAlert) {
    if (!alertClosed) {
        siteAlert.classList.remove('-hide');
    }

    siteAlertClose.addEventListener('click', () => {
        siteAlert.classList.add('-hide');
        sessionStorage.setItem('alertClosed', '1');
    });
}
