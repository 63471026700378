document.querySelectorAll('[data-select-highlight]').forEach(item => {
    let trigger = document.querySelector(item.dataset.selectHighlight);
    let toggle  = function (target) {
        if (target.checked) {
            item.classList.add('-highlight');
        } else {
            item.classList.remove('-highlight');
        }
    };

    trigger.addEventListener('change', function(e) {
        document.querySelector('[data-select-highlight][name="' + e.target.name + '"]').forEach(share => {
            if (share != item) {
                share.classList.remove('-highlight');
            }
        });

        toggle(e.target);
    });

    toggle(trigger);
});
