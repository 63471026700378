import ckconfig from '../../../../../../resources/scripts/ckconfig';

if ('heading' in ckconfig) {
    ckconfig.heading.options.push({
        model: 'eyebrow',
        view: {
            name: 'eyebrow',
            classes: 'type',
        },
        title: 'Eyebrow',
        classes: 'type'
    });
}

//
// Set CKEditor Config
//

window.ckconfig = ckconfig;
