const accordions = [...document.querySelectorAll('.js-accordion')];

/**
 * @function applyAccordionLogic Applies logic for toggling accordion
 * @param  {HTMLDivElement} accordion Div element for accordion module
 */
function applyAccordionLogic(accordion) {
    const accordionButton = accordion.querySelector('.js-accordionButton');
    const accordionContent = accordion.querySelector('.js-accordionContent');
    const accordionBody = accordion.querySelector('.js-accordionBody');

    accordionBody.removeAttribute('style');
    accordion.classList.remove('-active');

    accordionButton.addEventListener('click', () => {
        const { height } = accordionContent.getBoundingClientRect();

        if (!accordionBody.style.maxHeight.length) {
            accordion.classList.add('-active')
            accordionBody.style.maxHeight = `${height}px`;
        } else {
            accordion.classList.remove('-active');
            accordionBody.removeAttribute('style');
        }
    });
}

accordions.forEach(applyAccordionLogic);

if (!('moduleHandlers' in window)) {
    window.moduleHandlers = {}
}

window.moduleHandlers.accordion = applyAccordionLogic
