/**
 * Logic for Progress Bar Component
 * Author: Imarc 2019
 */

const progressBars = [...document.querySelectorAll('.js-progress')];

if (progressBars.length) {
    progressBars.forEach(function initializeProgressBar(progressBar) {
        const progress = progressBar.dataset.progress * 1;
        const meter = progressBar.querySelector('.js-meter');
        const percentage = `${progress * 100}%`;

        meter.style.width = percentage;
    });
}
