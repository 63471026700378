/*
// Text Toggle for click events
*/ 


const dataTextItems = document.querySelectorAll("[data-text-toggle]");

dataTextItems.forEach(dataText => {

    let initialText = dataText.innerText;
    let textToggle = dataText.dataset.textToggle;
    let textTrigger = dataText.dataset.textToggleTrigger;
    let triggeringElement = dataText;
    let lock = false;

    if (typeof textTrigger != 'undefined') {
        triggeringElement = document.querySelector(textTrigger)
    }

    triggeringElement.addEventListener("click", e => {
        if (!lock) {
            lock = true
            setTimeout(() => {
                dataText.innerText === initialText
                    ? (dataText.innerText = textToggle)
                    : (dataText.innerText = initialText);
                    lock = false
            }, 1)
        }
    });
    
});
